/* eslint-disable @typescript-eslint/naming-convention */
export enum ErrorCodes {
  InternalError = 500,
  success = 200,
  UnAuthorized = 401,
}

export enum ErrorTypes {
  Unhandled = "unhandled",
  Validation = "validation",
}
