import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "./style.css";
import { FC } from "react";

const BaseLayout: FC = () => {
  return (
    <>
      <Box className="header-wrapper">
        <Header />
      </Box>
      <Box className="base-section">
        <Outlet />
      </Box>
      <Footer/>
    </>
  );
};

export default BaseLayout;
