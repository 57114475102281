/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Divider, Grid, Container, Stack } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ReferenceLine,
} from "recharts";
import { FunctionComponent, useEffect, useState } from "react";
import request from "../../../Utils/request";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Helmet } from "react-helmet-async";
import CardDataLoader from "../../../components/DataLoader/CardDataLoader";
import { outputDateFormat } from "../../../Utils/date-format";
import NoDataAvailable from "../../../components/NoDataAvailable";

function TrendsAnalysis() {
  const [occupancies, setOccupancies] = useState<any[]>([]);
  const [mean, setMean] = useState<any>();
  const [startDate, setStartDate] = useState<Date>(new Date("05-01-2023"));
  const [endDate, setEndDate] = useState<Date | null>(new Date("05-30-2023"));
  const [thisYear, setThisYear] = useState<number>(new Date().getFullYear());
  const [loading, setLoading] = useState<boolean>(false);
  const [dateError, setDateError] = useState(false);
  const [yAxixData, setYAxixData] = useState<string>();

  useEffect(() => {
    if (occupancies.length !== 0) {
      const maxValueOfOccTy = Math.max(
        ...occupancies.map((obj) => obj["Occupancy This Year"]),
        0
      );
      const maxValueOfOccLy = Math.max(
        ...occupancies.map((obj) => obj["Occupancy Last Year"]),
        0
      );
      if (maxValueOfOccTy > maxValueOfOccLy) {
        setYAxixData("Occupancy This Year");
      } else {
        setYAxixData("Occupancy Last Year");
      }
    }
  }, [startDate, endDate, occupancies]);

  // const fetchHotelList = async () => {
  //   try {
  //     const response = await request.get("hotel", {
  //       params: {
  //         ...pagination,
  //       },
  //     });
  //     setHotelList(response.data.data);
  //     setSelectedHotelCode(response.data.data[0]?.hotelCode);
  //     setPagination((current: any) => {
  //       return { ...current, totalCount: response.data.count };
  //     });
  //   } catch (err: any) {
  //     handleError({}, err);
  //   }
  // };

  const fetchOccupancies = async () => {
    try {
      setLoading(true);
      if (endDate && startDate) {
        // const sDate = moment(startDate).format("MM/DD/YYYY");
        const sDate = outputDateFormat(startDate);
        const eDate = outputDateFormat(endDate);
        const response = await request.get(`report/occ-report`, {
          params: {
            startDate: sDate,
            endDate: eDate,
            // hotelId: selectedHotelCode,
          },
        });
        setOccupancies(response.data.data);
        setMean(response.data.meanVal);
        setLoading(false);
      }
    } catch (err: any) {
      // toast.error(err.message);
    }
  };

  useEffect(() => {
    if (endDate && startDate) {
      if (outputDateFormat(endDate) >= outputDateFormat(startDate)) {
        setDateError(false);
        fetchOccupancies();
      } else {
        setDateError(true);
      }
    } else {
      fetchOccupancies();
    }
  }, []);

  //  useEffect(() => {
  //   fetchOccupancies();
  // }, []);
  // const fetchEvent = async () => {
  //   try {
  //     if (endDate && startDate) {
  //       const sDate = moment(startDate).format("MM/DD/YYYY");
  //       const eDate = moment(endDate).format("MM/DD/YYYY");
  //       const response = await request.get(`event/event-by-date`, {
  //         params: {
  //           startDate: sDate,
  //           endDate: eDate,
  //         },
  //       });
  //       setEvent(response.data);
  //     }
  //   } catch (err: any) {
  //     // toast.error(err.message);
  //   }
  // };

  // const handleChartChange = (event: SelectChangeEvent) => {
  //   setSelectedHotelCode(event.target.value as string);
  // };
  const handleDateAccept = () => {
    if (endDate && startDate) {
      setDateError(false);
      fetchOccupancies();
    }
  };
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            border: "2px solid #DADADA",
            marginRight: 2,
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#FFFFFF"
          }}
        >
          {" "}
          <div style={{ padding: "4px" }}>
            <p className="label">
              {` ${payload[0].payload.Date} (TY),
                  ${payload[0].payload["LY Date"]}(LY)`}
            </p>
            <p
              className="label"
              style={{ color: "#4E91DD" }}
            >{`${payload[0].dataKey} : ${payload[0].value}`}</p>
            <p>
              {" "}
              <p
                className="label"
                style={{ color: "#A13764" }}
              >{`${payload[1].dataKey} : ${payload[1].value}`}</p>
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  };
  return (
    <Container sx={{ mt: 3 }}>
      <Helmet>
        <title>Occupancy: Trend Analysis</title>
      </Helmet>
      <Card>
        <Box p={2}>
          <Grid container spacing={2} mb={1}>
            <Grid item xs={6} md={8} justifyContent="start">
              <h3>Occupancy</h3>
            </Grid>
            <Grid item xs={3} md={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <MobileDatePicker
                    label={<>Start Date</>}
                    value={startDate === null ? null : startDate}
                    format="MM/dd/yyyy"
                    onChange={(newValue) => {
                      if (newValue) {
                        const date = moment(newValue).toDate();
                        setStartDate(date);
                        if (endDate) {
                          setEndDate(null);
                          setThisYear(moment(newValue).year());
                        }
                      }
                    }}
                    onAccept={handleDateAccept}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        InputProps: {
                          size: "medium",
                          placeholder: "Start Date",
                          name: "startDate",
                          fullWidth: true,
                        },
                      },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3} md={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <MobileDatePicker
                    label={<>End Date</>}
                    value={endDate === null ? null : endDate}
                    format="MM/dd/yyyy"
                    disableFuture
                    minDate={startDate}
                    maxDate={new Date(startDate.getFullYear(), 11, 31)}
                    onChange={(newValue) => {
                      if (newValue) {
                        const date = moment(newValue).toDate();
                        setEndDate(date);
                        setThisYear(moment(newValue).year());
                      }
                    }}
                    onAccept={handleDateAccept}
                    slotProps={{
                      textField: {
                        variant: "standard",
                        InputProps: {
                          size: "medium",
                          placeholder: "End Date",
                          name: "endDate",
                          fullWidth: true,
                        },
                      },
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>
          {dateError ? (
            <Grid
              item
              xs={3}
              md={3}
              mb={2}
              ml={2}
              sx={{ display: "flex", justifyContent: "right" }}
            >
              {" "}
              <span style={{ color: "red" }}>
                End date should be grater than Start date
              </span>
            </Grid>
          ) : null}
          {loading ? (
            <CardDataLoader />
          ) : (
            <>
              <Grid
                container
                display="flex"
                width="100%"
                height="100%"
                sx={{ mt: 1, mb: 2, border: "1px solid #DADADA" }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {" "}
                  <Grid
                    xs={12}
                    item
                    sx={{ mb: -1, display: "flex", justifyContent: "center" }}
                  >
                    <h3>
                      {" "}
                      Last Year ( {thisYear - 1} ) vs This Year ( {thisYear} ){" "}
                    </h3>
                    <br></br>
                  </Grid>
                  <Grid
                    xs={12}
                    item
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {" "}
                    <h4> Grand Hyatt Melbourne Hotel</h4>
                  </Grid>
                </Grid>
                {occupancies.length === 0 ? (
                  <>
                    <NoDataAvailable
                      message={"There is no data available for the chosen date range."}
                    />
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <LineChart
                        width={1000}
                        height={430}
                        data={occupancies}
                        margin={{
                          top: 16,
                          right: 50,
                          left: 20,
                          bottom: 40,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Date" tick={<CustomizedAxisTick />}>
                          <Label value="Date" offset={25} position="bottom" />
                        </XAxis>
                        <YAxis
                          dataKey={yAxixData}
                          label={{
                            value: "Occupancy",
                            angle: -90,
                            position: "insideLeft",
                          }}
                        />
                        <Tooltip content={<CustomTooltip />} />{" "}
                        <Legend verticalAlign="top" align="right" height={40} />{" "}
                        <Line
                          type="monotone"
                          dataKey="Occupancy This Year"
                          stroke="#4E91DD"
                          strokeWidth={2}
                          dot={{ fill: "#4E91DD", r: 1 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="Occupancy Last Year"
                          stroke="#A13764"
                          strokeWidth={2}
                          dot={{ fill: "#A13764", r: 1 }}
                        />
                        <ReferenceLine y={mean} stroke="#D1A34F">
                          <Label position={"right"} fill="#D1A34F">
                            Mean
                          </Label>
                        </ReferenceLine>
                        {/* {event.map((eventItem, index) => {
                      const fromDate = new Date(eventItem.fromDate);
                      const toDate = new Date(eventItem.toDate);

                      const eventStart =
                        fromDate < startDate ? startDate : fromDate;
                      const eventEnd = toDate > endDate ? endDate : toDate;

                      const formattedFromDate =
                        moment(eventStart).format("MMM DD");
                      const formattedToDate = moment(eventEnd).format("MMM DD");
                      if (formattedFromDate === formattedToDate) {
                        return (
                          <ReferenceLine
                            key={index}
                            x={formattedFromDate}
                            label={{
                              position: "top",
                              value: eventItem.name,
                              fill: "black",
                            }}
                            stroke="#7D9099"
                          />
                        );
                      } else {
                        return (
                          <ReferenceArea
                            key={index}
                            x1={formattedFromDate}
                            x2={formattedToDate}
                            label={eventItem.name}
                            fill="#DADADA"
                            fillOpacity={0.3}
                          />
                        );
                      }
                    })} */}
                      </LineChart>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </Box>

        <Divider />
      </Card>
    </Container>
  );
}

export default TrendsAnalysis;
