import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/material';
import { useState } from 'react';
import { FC } from 'react';
//import { Role } from '../../common/interface/Role';
import RoleListingTable from './RoleListingTable';
import CreateEditRole from './CreateEditRole';

const RoleApplications: FC = () => {
  const [showTable, setShowTable] = useState<boolean>(true);
  const [editStatus, setEditStatus] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [editData, setEditData] = useState<any>();
  return (
    <Container sx={{ mt: 3 }}>
      <Helmet>
        <title>Role Management: Role</title>
      </Helmet>
      {showTable ? (
        <RoleListingTable
          setShowTable={setShowTable}
          setEditStatus={setEditStatus}
          setEditData={setEditData}
        />
      ) : (
        <CreateEditRole
          setShowTable={setShowTable}
          editData={editData}
          setEditData={setEditData}
          editStatus={editStatus}
          setEditStatus={setEditStatus}
        />
      )}
    </Container>
  );
};

export default RoleApplications;
