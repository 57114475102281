import { LoadingButton } from '@mui/lab';

interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ButtonCancel: React.FC<ButtonProps> = ({
  children,
  onClick,
  loading = false,
  disabled = false,
  ...rest
}) => {
  return (
    <LoadingButton
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      variant="contained"
      size="small"
      color="error"
      sx={{ mr: 2 }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

export default ButtonCancel;
