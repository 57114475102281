import { FC, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Feature } from "../../common/interface/Feature";
import request from "../../Utils/request";
import { ErrorResponse } from "../../common/interface/ErrorResponse";
import { handleError } from "../../Utils/error-utils";
import ButtonSave from "../../components/Buttons/ButtonSave";
import ButtonCancel from "../../components/Buttons/ButtonCancel";

interface CreateRoleProps {
  setShowTable: (show: boolean) => void;
  setEditData: (data: Feature | null) => void;
  editData: Feature | null;
  editStatus: boolean;
  setEditStatus: (status: boolean) => void;
}

const roleValidationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  action: Yup.string().required("Action is required"),
  module: Yup.string().required("Module is required"),
  feature: Yup.string().required("Feature is required"),
});
const CreateEdit: FC<CreateRoleProps> = ({
  setShowTable,
  setEditData,
  editData,
  editStatus,
  setEditStatus,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const roleInitialValue = {
    id: "",
    name: "",
    module: "",
    feature: "",
    action: "",
  };
  const formik = useFormik({
    initialValues: roleInitialValue,
    validationSchema: roleValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const featureData = {
          name: values?.name,
          module: values?.module,
          action: values?.action,
          feature: values?.feature,
        };

        if (editStatus) {
          await request.put(`roles/${editData?.id}`, featureData);
          formik.resetForm();
          setEditStatus(false);
        } else {
          await request.post("feature", featureData);
          formik.resetForm();
        }
        setShowTable(true);
        setEditData(null);
      } catch (err) {
        const error = err as ErrorResponse;
        handleError(formik, error);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (editStatus) {
      formik.setValues({
        id:  editData?.id || "", 
        name: editData?.name || "",
        module: editData?.module || "",
        feature: editData?.feature || "",
        action: editData?.action || "",
      });
    }
  }, [editStatus]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader title={"Create Feature"} />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label={
                  <>
                    Module
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="module"
                value={formik?.values?.module}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.module && Boolean(formik.errors?.module)}
                helperText={formik.touched?.module && formik.errors?.module}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={
                  <>
                    Name
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="name"
                value={formik?.values?.name}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.name && Boolean(formik.errors?.name)}
                helperText={formik.touched?.name && formik.errors?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={
                  <>
                    Feature
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="feature"
                value={formik?.values?.feature}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={
                  formik.touched?.feature && Boolean(formik.errors?.feature)
                }
                helperText={formik.touched?.feature && formik.errors?.feature}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={
                  <>
                    Action
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="action"
                value={formik?.values?.action}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.action && Boolean(formik.errors?.action)}
                helperText={formik.touched?.action && formik.errors?.action}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <ButtonSave loading={loading}>Save</ButtonSave>
              <ButtonCancel
                onClick={() => {
                  setEditData(null);
                  setEditStatus(false);
                  setShowTable(true);
                }}
              >
                Cancel
              </ButtonCancel>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default CreateEdit;
