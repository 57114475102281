import { Box, Grid, Typography } from "@mui/material";
// import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';
import { styled } from "@mui/material/styles";
import LogoWrapper from "../../Layouts/BaseLayout/Header/blocks/LogoWrapper";
import ChangePass from "./ChangePassword";

const BoxWrapper = styled(Box)(() => ({
  flex: 1,
  alignItems: "center",
}));

function Change() {
  return (
    <>
      <Box className="loginWrapper">
        <Box className="loginLogo">
          <LogoWrapper />
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={12} md={1} lg={1}></Grid>
          <Grid item xs={8} md={7} lg={7}>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box className="loginCard">
              <BoxWrapper sx={{ pt: 3 }}>
              <Typography
                  variant="h2"
                  style={{
                    textAlign: "center",
                  }}
                >
                  Change your password?
                </Typography>{" "}                <ChangePass />
              </BoxWrapper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Change;
