/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useContext } from "react";
// import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from "../../../contexts/SidebarContext";
import Logo from "./../../../components/Logo";

import { Box, Drawer, Hidden } from "@mui/material";

import { styled } from "@mui/material/styles";
import SidebarMenu from "./SidebarMenu";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        padding-bottom: 60px;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 80px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
        border-bottom: ${theme.colors.primary.main} solid 1px;
        border-bottom-width: 3px;
`
);

// const SidebarFooter = styled(Box)(() => ({
//   height: "60px",
//   backgroundColor: "rgb(17, 137, 230, 0.9)",
//   alignItems: "center",
// }));

const Sidebar: FC<any> = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          {/* <Scrollbars autoHide> */}
          <TopSection>
            <Box>
              <Logo />
            </Box>
          </TopSection>
          <SidebarMenu />
          {/* </Scrollbars> */}
          
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            {/* <Scrollbars autoHide> */}
            <TopSection>
              <Logo />
            </TopSection>
            <SidebarMenu />
            {/* </Scrollbars> */}
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
