import loader from './loader.gif';
import { TableCell, TableFooter, TableRow } from '@mui/material';

const TableDataLoader = () => {
  return (
    <TableFooter>
      <TableRow>
        <TableCell>
          <img
            alt="loader"
            src={loader}
            className="loader"
            width={20}
            height={20}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
export default TableDataLoader;
