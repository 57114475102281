import { FC, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  Paper,
  TableBody,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Role } from "../../../common/interface/Role";
import { FeatureData } from "../../../common/interface/FeatureData";
import { UserFeature } from "../../../common/interface/UserFeature";
import { RoleFeature } from "../../../common/interface/RoleFeature";
import ButtonCancel from "../../../components/Buttons/ButtonCancel";
import ButtonSave from "../../../components/Buttons/ButtonSave";
import TableDataLoader from "../../../components/DataLoader/TableDataLoader";
import request from "../../../Utils/request";
import { ErrorResponse } from "../../../common/interface/ErrorResponse";
import { handleError } from "../../../Utils/error-utils";
import { User } from "@common/interface/User";

interface CreateRoleProps {
  setCurrentView: (currentView: string) => void;
  editUserData: User | null;
}

const RoleAllocation: FC<CreateRoleProps> = ({
  setCurrentView,
  editUserData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [getRoles, setRoles] = useState<Role[]>();
  const [getFeature, setFeature] = useState<FeatureData[]>();
  const [getUserFeature, setUserFeature] = useState<UserFeature | null>();
  const [getOneRole, setOneRole] = useState<RoleFeature>();
  const roleInitialValue = {
    email: "",
    roleID: "",
    feature: [] as string[],
  };

  const roleValidationSchema = Yup.object({
    roleID: Yup.string().required("A Role ID is required"),
    feature: Yup.array()
      .min(1, "You should select atleast one checkbox")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: roleInitialValue,
    validationSchema: roleValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const roleData = {
          userID: editUserData?.id,
          roleID: values?.roleID,
          feature: values?.feature,
        };
        await request.post("roles/user/feature", roleData);
        setCurrentView("showTable");
      } catch (err) {
        const error = err as ErrorResponse;
        handleError(formik, error);
      }
      setLoading(false);
    },
  });

  const getAllUserFeature = async () => {
    try {
      const response = await request.get(
        `user/rolefeatures/${editUserData?.id}`
      );
      setUserFeature(response.data.user);
      setFeature(response.data.features);
    } catch (err) {
      const error = err as ErrorResponse;
      handleError(formik, error);
    }
  };

  const getRole = async () => {
    try {
      const response = await request.get(`roles/${formik.values?.roleID}`);
      setOneRole(response.data.role);
      setFeature(response.data.features);
    } catch (err) {
      const error = err as ErrorResponse;
      handleError(formik, error);
    }
  };
  useEffect(() => {
    if (editUserData) {
      formik.setValues((previousData) => {
        return {
          ...previousData,
          email: editUserData?.email,
          roleID: editUserData?.roleID,
        };
      });
    }
  }, [editUserData]);

  const handleFeature = (featureArray: { id: string }[], moduleID: any) => {
    if (moduleID === true) {
      formik.setValues((previousData) => ({
        ...previousData,
        feature: [
          ...featureArray.map((data: any) => data.id),
          ...previousData.feature,
        ],
      }));
    } else {
      formik.setValues((previousData) => ({
        ...previousData,
        feature: previousData.feature.filter(
          (val) => !featureArray.map((data: any) => data.id).includes(val)
        ),
      }));
    }
  };

  const getAllRoles = async () => {
    try {
      const response = await request.get("roles", {
        params: {
          type: editUserData?.userType,
        },
      });
      setRoles(response.data.data);
    } catch (err) {
      const error = err as ErrorResponse;
      handleError(formik, error);
    }
  };

  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    if (formik.values.roleID) {
      if (formik.values.roleID === editUserData?.roleID) {
        getAllUserFeature();
      } else {
        setUserFeature(null);
        getRole();
      }
    }
  }, [formik.values.roleID]);

  useEffect(() => {
    if (getUserFeature) {
      formik.setValues({
        email: formik.values.email,
        roleID: formik.values.roleID,
        feature: getUserFeature?.feature?.map((data) => data.id),
      });
    } else if (getOneRole) {
      formik.resetForm();
      formik.setValues({
        email: formik.values.email,
        roleID: formik.values.roleID,
        feature: getOneRole?.feature?.map((data) => data.id),
      });
    }
  }, [getUserFeature, getOneRole]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader title={`Assign Role `} />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="User"
                name="user"
                value={formik.values.email}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.email && Boolean(formik.errors?.email)}
                helperText={formik.touched?.email && formik.errors?.email}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  error={formik.touched.roleID && Boolean(formik.errors.roleID)}
                >
                  Select Role
                </InputLabel>
                <Select
                  value={formik.values.roleID}
                  name="roleID"
                  onChange={formik.handleChange}
                  label="Select Role"
                  error={
                    formik?.touched?.roleID && Boolean(formik?.errors?.roleID)
                  }
                >
                  {getRoles?.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* {formik?.touched?.roleID && formik?.errors?.roleID && (
                <ErrorMessage>{formik?.errors?.roleID}</ErrorMessage>
              )} */}
            </Grid>
            <Grid item xs={12}>
              {formik.values.roleID &&
                getFeature?.map((feature, index) => {
                  return (
                    <TableContainer component={Paper} key={index}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>{feature.module}</TableCell>
                            <TableCell>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) => {
                                      const moduleID = e.target.checked;
                                      handleFeature(
                                        feature.featureName,
                                        moduleID
                                      );
                                    }}
                                    checked={feature.featureName.some(
                                      (item: any) =>
                                        formik.values.feature?.includes(
                                          item?.id
                                        )
                                    )}
                                  />
                                }
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {loading ? (
                          <TableDataLoader />
                        ) : (
                          <TableBody>
                            {feature.featureName.map((featureData, index) => (
                              <TableRow key={index}>
                                <TableCell width="80%">
                                  {featureData.name}
                                </TableCell>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name={"feature"}
                                        value={featureData.id}
                                        onChange={formik.handleChange}
                                        checked={formik.values.feature?.some(
                                          (data) => data === featureData.id
                                        )}
                                      />
                                    }
                                    label=""
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  );
                })}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <ButtonSave loading={loading}>Save</ButtonSave>
              <ButtonCancel
                onClick={() => {
                  setCurrentView("showTable");
                }}
              >
                Cancel
              </ButtonCancel>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default RoleAllocation;
