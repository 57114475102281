import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { User } from "../../common/interface/User";
import { PaginationData } from "../../common/interface/PaginationData";
import request from "../../Utils/request";
import { handleError } from "../../Utils/error-utils";
import NoDataAvailable from "../../components/NoDataAvailable";
import TableDataLoader from "../../components/DataLoader/TableDataLoader";
import DeleteDialog from "../../components/DeleteDialog";
import { toast } from "react-toastify";

interface UserTableProps {
  setCurrentView: (newData: string) => void;
  setUserData: (newData: User) => void;
  editUserData: User | null;
}
const UserTable: FC<UserTableProps> = ({
  setCurrentView,
  setUserData,
  editUserData,
}) => {
  const [user, setUser] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [pagination, setPagination] = useState<PaginationData>({
    search: "",
    offset: 0,
    limit: 25,
    orderDirection: "DESC",
    orderColumn: "createdAt",
    totalCount: 0,
  });

  useEffect(() => {
    fetchUser();
  }, [
    pagination.offset,
    pagination.limit,
    pagination.search,
    pagination.orderColumn,
    pagination.orderDirection,
  ]);

  const fetchUser = async () => {
    try {
      setLoading(true);
      const response = await request.get("user", {
        params: {
          ...pagination,
        },
      });
      setUser(response.data.data);
      setPagination((current) => {
        return { ...current, totalCount: response.data.count };
      });
      setLoading(false);
    } catch (err: any) {
      handleError({}, err);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await request.delete("user/" + editUserData?.id);
      if (response.data.status) {
        toast.success(response.data.message);
        setOpenDialogBox(false);
        fetchUser();
      }
    } catch (err) {
      setOpenDialogBox(false);
    }
    setLoading(false);
  };

  return (
    <TableContainer component={Paper}>
      <>
        {openDialogBox ? (
          <DeleteDialog
            openDeleteDialog={openDialogBox}
            setOpenDeleteDialog={setOpenDialogBox}
            handleDelete={handleDelete}
            deleteDataName={editUserData?.name ?? "User"}
            loading={loading}
          ></DeleteDialog>
        ) : null}
        <Toolbar sx={{ m: 1 }}>
          <Typography sx={{ flex: "1 1 100%" }} variant="h4" component="div">
            Users
          </Typography>
          <Tooltip title="Create User">
            <IconButton
              onClick={() => setCurrentView("create")}
              color="primary"
              size="small"
              sx={{ mt: -1, ml: 2 }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>#</Typography>
              </TableCell>
              <TableCell>
                <Typography>Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>Email</Typography>
              </TableCell>
              <TableCell>
                <Typography>Phone</Typography>
              </TableCell>
              <TableCell>
                <Typography>Type</Typography>
              </TableCell>
              <TableCell>
                <Typography>Status</Typography>
              </TableCell>
              <TableCell>
                <Typography align="center">Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableDataLoader />
          ) : (
            <>
              {user.length ? (
                <TableBody>
                  {user?.map((user, index) => (
                    <TableRow key={user?.id}>
                      <TableCell>
                        {pagination.offset * pagination.limit + index + 1}
                      </TableCell>
                      <TableCell>{user?.name}</TableCell>
                      <TableCell>{user?.email}</TableCell>
                      <TableCell>{user?.phone}</TableCell>
                      <TableCell>{user?.userType}</TableCell>
                      <TableCell>{user?.status}</TableCell>
                      <TableCell align="center">
                        <Tooltip title={"Assign Role"}>
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => {
                              setUserData(user);
                              setCurrentView("assign");
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Edit User"}>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              setUserData(user);
                              setCurrentView("update");
                            }}
                          >
                            <EditTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={"Delete Role"}>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              setUserData(user);
                              setOpenDialogBox(true);
                            }}
                          >
                            <DeleteTwoToneIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <NoDataAvailable message="No User Found" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
      </>
    </TableContainer>
  );
};

export default UserTable;
