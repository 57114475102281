import DashboardIcon from "@mui/icons-material/Dashboard";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import LivingIcon from "@mui/icons-material/Living";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import EqualizerIcon from "@mui/icons-material/Equalizer";
// import StorageIcon from "@mui/icons-material/Storage";
// import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
// import GroupIcon from "@mui/icons-material/Group";
// import ArticleIcon from "@mui/icons-material/Article";
// import RoomServiceIcon from "@mui/icons-material/RoomService";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
// import SettingsIcon from "@mui/icons-material/Settings";
// import PostAddIcon from "@mui/icons-material/PostAdd";
// import CompareIcon from "@mui/icons-material/Compare";
import PieChartIcon from "@mui/icons-material/PieChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import EventIcon from "@mui/icons-material/Event";
// import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
export interface MenuItem {
  link?: string;
  icon?: OverridableComponent<SvgIconTypeMap<any, "svg">> & {
    muiName: string;
  };
  badge?: string;
  items?: MenuItem[];
  name: string;
  key?: string;
}

export interface MenuItems {
  items: MenuItem[];
}

const menuItems: MenuItems[] = [
  {
    items: [
      {
        name: "Dashboard",
        link: "/dashboard",
        icon: DashboardIcon,
      },
      {
        name: "Benchmarking",
        link: "/",
        icon: EqualizerIcon,
        items: [
          {
            name: "Occupancy",
            link: "/",
            icon: LivingIcon,
            items: [
              {
                name: "Trend Analysis",
                link: "/benchmarking/occupancy/trend-analysis",
                icon: WhatshotIcon,
              },
              {
                name: "Competitive Analysis",
                link: "/benchmarking/occupancy/competitive-analysis",
                icon: QueryStatsIcon,
              },
              // {
              //   name: "Performance Comparison",
              //   link: "/benchmarking/performance-comparison",
              //   icon: CompareIcon,
              // },
              {
                name: "Occupancy Prediction",
                link: "/benchmarking/occupancy/occupancy-prediction",
                icon: StackedBarChartIcon,
              },
              {
                name: "Index",
                link: "/benchmarking/occupancy/index",
                icon: PieChartIcon,
              },
            ],
          },
          {
            name: "Revenue Reports",
            link: "/dashboard/revenue",
            icon: ShowChartIcon,
            items: [
              {
                name: "Trend Analysis",
                link: "/benchmarking/revenue/trend-analysis",
                icon: WhatshotIcon,
              },
              {
                name: "Competitive Analysis",
                link: "/benchmarking/revenue/competitive-analysis",
                icon: QueryStatsIcon,
              },
            ],
          },
          {
            name: "ADR Reports",
            link: "/dashboard/adr",
            icon: AutoGraphIcon,
            items: [
              {
                name: "Trend Analysis",
                link: "/benchmarking/adr/trend-analysis",
                icon: WhatshotIcon,
              },
              {
                name: "Competitive Analysis",
                link: "/benchmarking/adr/competitive-analysis",
                icon: QueryStatsIcon,
              },
            ],
          },
          {
            name: "ADR vs RevPAR",
            link: "/benchmarking/adr-vs-revpar",
            icon: LocalAtmIcon,
          },

          // {
          //   name: "Guest Satisfaction",
          //   link: "/dashboard/guest_satisfaction",
          //   icon: TagFacesIcon,
          // },
        ],
      },
      // {
      //   name: "Entities",
      //   link: "",
      //   icon: GroupAddIcon,
      //   items: [
      //     {
      //       name: "Organizations",
      //       link: "/entities/hotel-management-group",
      //       icon: GroupIcon,
      //     },
      //     {
      //       name: "Hotel Groups",
      //       link: "/entities/hotel-group",
      //       icon: ArticleIcon,
      //     },
      //     {
      //       name: "Hotels",
      //       link: "/entities/hotels",
      //       icon: RoomServiceIcon,
      //     },
      //   ],
      // },
      // {
      //   name: "User Management",
      //   link: "/user",
      //   icon: PermContactCalendarIcon,
      //   items: [
      //     {
      //       name: "User",
      //       link: "/user",
      //       icon: GroupIcon,
      //     },
      //     {
      //       name: "Feature",
      //       link: "/user/feature",
      //       icon: ArticleIcon,
      //     },
      //     {
      //       name: "Role",
      //       link: "/user/role",
      //       icon: StorageIcon,
      //     },
      //   ],
      // },
      // {
      //   name: "Settings",
      //   link: "",
      //   icon: SettingsIcon,
      //   items: [
      //     {
      //       name: "Report",
      //       link: "/settings/report",
      //       icon: PostAddIcon,
      //     },
      {
        name: "Event",
        link: "/settings/event",
        icon: EventIcon,
      },
      //     {
      //       name: "PMS",
      //       link: "/settings/pms",
      //       icon: ManageAccountsIcon,
      //     },

      //   ],
      // },
    ],
  },
];

export default menuItems;
