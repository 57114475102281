import * as React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoWrapper from "./blocks/LogoWrapper";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  // List,
  // ListItem,
  // ListItemButton,
  // ListItemText,
  Toolbar,
  // Button,
  Container,
} from "@mui/material";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
// const navItems = ["Why HACS", "Pricing", "Solutions", "Resources"];

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "start", backgroundColor: "#3C339A", color: "#fff" }}
    >
      <Box
        sx={{
          my: 2,
          display: "flex",
          justifyContent: { xs: "center", md: "start" },
        }}
      >
        <LogoWrapper />
      </Box>
      <Divider />
      {/* <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "start" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" className="main-header">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <Box className="mobMenuWrapper">
                <MenuIcon />
              </Box>
            </IconButton>
            <Box
              component="div"
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: { xs: "end", md: "start" },
              }}
            >
              <LogoWrapper />
            </Box>
            {/* <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button className="navItem" key={item}>
                  {item}
                </Button>
              ))}
              <Button variant="outlined" className="outlined-white">
                Start For Free
              </Button>
            </Box> */}

            {/* <a href="/dashboard">
              <Button variant="outlined" className="outlined-white">
                Login
              </Button>
            </a> */}
          </Toolbar>
        </AppBar>

        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "#3C339A",
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </Container>
  );
}
