import { Alert, Grid } from "@mui/material";
import { FC } from "react";

interface Props {
  message: string;
}

const NoDataAvailable: FC<Props> = ({ message }) => {
  return (
    <>
      <Grid
        width="100%"
        height="100%"
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          mb: 2,
          border: "1px solid #DADADA",
        }}
      >
        <Alert severity="warning" sx={{ backgroundColor: "white",color:'red' }}>
          {message}
        </Alert>
      </Grid>
    </>
  );
};

export default NoDataAvailable;
