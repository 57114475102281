import React, { useEffect, useRef } from "react";
import ThemeProvider from "./Theme/themeProvider";
import { CssBaseline } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useRoutes } from "react-router-dom";
import "../src/Sass/App.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import baseSiteRoute from "./routes/SiteRoutes";
import adminRoutes from "./routes/AdminRoutes";
import moment from "moment";
import refreshTokenService from "./Utils/refresh-request";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const ref = useRef<any>(null);
  const localStorageData: any = localStorage?.getItem("userData");
  const userData = JSON.parse(localStorageData);
  // const loggedUser = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 30000);
    ref.current = interval;
    return () => clearInterval(interval);
  }, []);

  const refreshToken = async () => {
    const expiryTime: any = localStorage?.getItem("expiry");

    const tokenExpiry = JSON.parse(expiryTime);
    if (
      tokenExpiry?.refreshAt &&
      moment(tokenExpiry?.refreshAt).diff(moment(), "second") < 15
    ) {
      try {
        const response = await refreshTokenService.get(`auth/refresh`);
        localStorage.setItem("userData", JSON.stringify(response.data));
        const expiry = response?.data?.expiry - 20;
        const refreshAt = moment().add({ seconds: expiry });
        localStorage.setItem("expiry", JSON.stringify({ refreshAt }));
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {userData ? useRoutes(adminRoutes) : useRoutes(baseSiteRoute)}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
