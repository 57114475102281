import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Card,
  RadioGroup,
  CardHeader,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { useEffect, useState, FC } from "react";
import request from "../../../Utils/request";
import { toast } from "react-toastify";
import { User } from "@common/interface/User";
import { EntityType } from "../../../common/enums/EntityType";
import ButtonSave from "../../../components/Buttons/ButtonSave";
import ButtonCancel from "../../../components/Buttons/ButtonCancel";

interface HotelAllocationProps {
  userEditData: User | null;
  setCurrentView: (currentView: string) => void;
}

const HotelAllocation: FC<HotelAllocationProps> = ({
  userEditData,
  setCurrentView,
}) => {
  const [organizationData, setOrganizationData] = useState<any[]>([]);
  const [groupData, setGroupData] = useState<any[]>([]);
  const [hotelData, setHotelData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>(
    userEditData?.entities ?? []
  );

  useEffect(() => {
    request
      .get(
        "/hotel-management-group?offset=0&limit=25&orderColumn=id&orderDirection=ASC"
      )
      .then(
        (response) => {
          setOrganizationData([...response.data.data]);
        },
        (error) => {
          toast.error("Error: " + error.message);
        }
      );
    if (selectedData.length > 0) {
      //  Edit case
      //  fetch list of Hotel Groups associated with user assigned Organizations
      request
        .get("/hotel-group/hmg/id", {
          params: {
            parentIds: selectedData
              .filter((item) => item.type === EntityType.Organization)
              .map((item) => item.id),
          },
        })
        .then(
          (response) => {
            const array = groupData.concat(response.data);
            setGroupData([...array]);
          },
          (error) => {
            toast.error("Error: " + error.message);
          }
        );

      // fetch list of Hotels associated with user assigned HotelGroups
      request
        .get("hotel/hg/id", {
          params: {
            parentIds: selectedData
              .filter((item) => item.type === EntityType.Group)
              .map((item) => item.id),
          },
        })
        .then(
          (response) => {
            setHotelData(response.data);
          },
          (error) => {
            toast.error("Error: " + error.message);
          }
        );
    }
  }, []);

  const CheckBoxAction = (e: any, current: any, action: string) => {
    if (e.currentTarget.checked) {
      if (action === "org_action") {
        //  Prevent API call for already fetched group data
        if (groupData.some((item) => item.hmGroupId === current.id) === false) {
          request
            .get("/hotel-group/hmg/id", {
              params: {
                parentIds: [current.id],
              },
            })
            .then(
              (response) => {
                const array = groupData.concat(response.data);
                setGroupData([...array]);
              },
              (error) => {
                toast.error("Error: " + error.message);
              }
            );
        }
        setSelectedData((prevData) => {
          prevData.push({ ...current, allocationType: "organization" });
          return [...prevData];
        });
      } else if (action === "grp_action") {
        //  Prevent API call for already fetched hotel data\
        if (hotelData.some((item) => item.groupId === current.id) === false) {
          request
            .get("hotel/hg/id", {
              params: {
                parentIds: [current.id],
              },
            })
            .then(
              (response) => {
                const array = hotelData.concat(response.data);
                setHotelData([...array]);
              },
              (error) => {
                toast.error("Error: " + error.message);
              }
            );
        }
        setSelectedData((prevData) => {
          prevData.push({ ...current, allocationType: "group" });
          return [...prevData];
        });
      } else {
        setSelectedData((prevData) => {
          prevData.push({ ...current, allocationType: "hotel" });
          return [...prevData];
        });
      }
    } else {
      setSelectedData((prevData) => {
        return [...prevData.filter((item) => item.id !== current.id)];
      });
    }
  };

  console.log(selectedData);

  const radioButtonAction = (e: any, action: any, parentId: any) => {
    if (e.currentTarget.value === "custom") {
      // Custom action logic
    } else {
      if (action === "org_action") {
        setSelectedData((prevData) => {
          return [
            ...prevData.filter((item) => item.type !== EntityType.Organization),
            ...organizationData.map((data) => {
              return { ...data, allocationType: "organization" };
            }),
          ];
        });
        request
          .get("/hotel-group/hmg/id", {
            params: {
              parentIds: organizationData.map((item) => item.id),
            },
          })
          .then(
            (response) => {
              setGroupData((prevData) => {
                return [
                  ...prevData.filter((i) =>
                    organizationData.includes((j: any) => !i.hmGroupId === j.id)
                  ),
                  ...response.data,
                ];
              });
            },
            (error) => {
              toast.error("Error: " + error.message);
            }
          );
      } else if (action === "grp_action") {
        setSelectedData((prevData) => {
          return [
            ...prevData.filter((item) => item.hmGroupId !== parentId),
            ...groupData
              .filter((item) => item.hmGroupId === parentId)
              .map((data) => {
                return { ...data, allocationType: "group" };
              }),
          ];
        });
        request
          .get("hotel/hg/id", {
            params: {
              parentIds: [
                ...groupData
                  .filter((item) => item.hmGroupId === parentId)
                  .map((data) => data.id),
              ],
            },
          })
          .then(
            (response) => {
              setHotelData((prevData) => {
                return [
                  ...prevData.filter((i) =>
                    organizationData.includes((j: any) => !i.hmGroupId === j.id)
                  ),
                  ...response.data,
                ];
              });
            },
            (error) => {
              toast.error("Error: " + error.message);
            }
          );
      } else {
        setSelectedData((prevData) => {
          return [
            ...prevData.filter((item) => item.groupId !== parentId),
            ...hotelData
              .filter((item) => item.groupId === parentId)
              .map((data) => {
                return { ...data, allocationType: "hotel" };
              }),
          ];
        });
      }
    }
  };

  const handleSave = async () => {
    try {
      await request.post("/user/allocate-hotel", {
        userId: userEditData?.id,
        userAllocationArray: selectedData,
      });
      setCurrentView("showTable");
    } catch (error) {
      console.log(error);
    }
  };

  console.log(selectedData, userEditData?.id);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Card>
          <CardHeader title={`Allocate Hotel`} />
          <Accordion
            sx={{ border: 1, borderColor: "#b5b3b3" }}
            expanded={true}
            // expanded={expandedList.some((item) => item === 'org_accordion')}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <FormControl component="fieldset">
                  <Grid container spacing={2}>
                    <Grid item sx={{ marginTop: 1.4 }}>
                      Assign to
                    </Grid>
                    <Grid item>
                      <RadioGroup
                        row
                        aria-label="org_action"
                        name="row-radio-buttons-group"
                        value={
                          selectedData.filter((i) => i.type === "organization")
                            .length === organizationData.length
                            ? "all"
                            : "custom"
                        }
                        onChange={(e) =>
                          radioButtonAction(e, "org_action", "org_accordion")
                        }
                      >
                        <FormControlLabel
                          value="all"
                          control={<Radio />}
                          label="All Organizations"
                        />
                        <FormControlLabel
                          value="custom"
                          control={<Radio />}
                          label="Custom"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </FormControl>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {organizationData.map((org) => (
                <Accordion
                  key={org.id}
                  sx={{ border: 1, borderColor: "#b5b3b3" }}
                  // expanded={expandedList.some((item) => item === org.id)}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <FormControl component="fieldset">
                        <Grid container spacing={2}>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={org.id}
                                  checked={selectedData.some(
                                    (item) => item?.id === org?.id
                                  )}
                                  onChange={(e) =>
                                    CheckBoxAction(e, org, "org_action")
                                  }
                                />
                              }
                              label={org.name}
                            />
                          </Grid>
                          <Grid item>
                            {selectedData.some(
                              (item) => item?.id === org?.id
                            ) ? (
                              <RadioGroup
                                row
                                aria-label="grp_action"
                                name="row-radio-buttons-group"
                                value={
                                  selectedData.filter(
                                    (selected) =>
                                      selected.type === "group" &&
                                      selected.hmGroupId === org.id
                                  ).length ===
                                  groupData.filter(
                                    (group) => group.hmGroupId === org.id
                                  ).length
                                    ? "all"
                                    : "custom"
                                }
                                onChange={(e) =>
                                  radioButtonAction(e, "grp_action", org.id)
                                }
                              >
                                <FormControlLabel
                                  value="all"
                                  control={<Radio />}
                                  label="All Groups"
                                />
                                <FormControlLabel
                                  value="custom"
                                  control={<Radio />}
                                  label="Custom"
                                />
                              </RadioGroup>
                            ) : null}
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {groupData.map((grp) =>
                      grp?.hmGroupId === org.id ? (
                        <Accordion
                          key={grp.id}
                          sx={{ border: 1, borderColor: "#b5b3b3" }}
                          // expanded={expandedList.some(
                          //   (item) => item === grp.id
                          // )}
                        >
                          <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>
                              <FormControl component="fieldset">
                                <Grid container spacing={2}>
                                  <Grid item>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          value={grp.id}
                                          checked={selectedData.some(
                                            (item) => item?.id === grp?.id
                                          )}
                                          onChange={(e) =>
                                            CheckBoxAction(e, grp, "grp_action")
                                          }
                                        />
                                      }
                                      label={grp.name}
                                    />
                                  </Grid>
                                  <Grid item>
                                    {selectedData.some(
                                      (item) => item?.id === grp?.id
                                    ) ? (
                                      <RadioGroup
                                        row
                                        aria-label="htl_action"
                                        name="row-radio-buttons-group"
                                        value={
                                          selectedData.filter(
                                            (selected) =>
                                              selected.type === "hotel" &&
                                              selected.hotelGroupId === grp?.id
                                          ).length ===
                                          hotelData.filter(
                                            (hotel) =>
                                              hotel.hotelGroupId === grp?.id
                                          ).length
                                            ? "all"
                                            : "custom"
                                        }
                                        onChange={(e) =>
                                          radioButtonAction(
                                            e,
                                            "htl_action",
                                            grp.id
                                          )
                                        }
                                      >
                                        <FormControlLabel
                                          value="all"
                                          control={<Radio />}
                                          label="All Hotels"
                                        />
                                        <FormControlLabel
                                          value="custom"
                                          control={<Radio />}
                                          label="Custom"
                                        />
                                      </RadioGroup>
                                    ) : null}
                                  </Grid>
                                </Grid>
                              </FormControl>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Divider />
                            {hotelData.map((htl) =>
                              htl?.hotelGroupId === grp.id ? (
                                <MenuItem key={htl.id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        value={htl.id}
                                        checked={selectedData.some(
                                          (item) => item?.id === htl?.id
                                        )}
                                        onChange={(e) =>
                                          CheckBoxAction(e, htl, "htl_action")
                                        }
                                      />
                                    }
                                    label={htl.name}
                                  />
                                </MenuItem>
                              ) : null
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ) : null
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
            <Grid item xs={12} sx={{ textAlign: "center", margin: 2 }}>
              <ButtonSave loading={false} onClick={() => handleSave()}>
                Save
              </ButtonSave>
              <ButtonCancel
                onClick={() => {
                  setCurrentView("showTable");
                }}
              >
                Cancel
              </ButtonCancel>
            </Grid>
          </Accordion>
        </Card>
      </Grid>
    </Grid>
  );
};

HotelAllocation.propTypes = {
  userEditData: PropTypes.any.isRequired,
  setCurrentView: PropTypes.func.isRequired,
};

export default HotelAllocation;
