import { useState, ChangeEvent, FC } from "react";
import { Helmet } from "react-helmet-async";
import { Tabs, Tab, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import HotelAllocation from "./HotelAllocation";
import RoleAllocation from "./RoleAllocation";
import { User } from "../../../common/interface/User";

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

interface SettingsProps {
  setCurrentView: (newData: string) => void;
  currentView: string;
  editUserData: User | null;
}

const Settings: FC<SettingsProps> = ({ setCurrentView, editUserData }) => {
  const [currentTab, setCurrentTab] = useState<string>("assign_role");

  const tabs = [
    { value: "assign_role", label: "Assign Role" },
    { value: "allocate_hotel", label: "Allocate Hotel" },
  ];

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet>
        <title>User Settings</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <TabsWrapper
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </TabsWrapper>
        </Grid>
        <Grid item xs={12}>
          {currentTab === "assign_role" && (
            <RoleAllocation
              editUserData={editUserData}
              setCurrentView={setCurrentView}
            />
          )}
          {currentTab === "allocate_hotel" && (
            <HotelAllocation
              userEditData={editUserData}
              setCurrentView={setCurrentView}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

Settings.propTypes = {
  currentView: PropTypes.string.isRequired,
  setCurrentView: PropTypes.func.isRequired,
  editUserData: PropTypes.any.isRequired,
};

export default Settings;
