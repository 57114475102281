import React from "react";
import { Link } from "react-router-dom";
import Logo from "../img/hacs-logo.svg";

function LogoWrapper() {
  return (
    <Link to="/">
      <img className="img-fluid" src={Logo} alt="HACS" />
    </Link>
  );
}

export default LogoWrapper;
