import { LoadingButton } from "@mui/lab";

interface ButtonProps {
  children?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const ButtonSave: React.FC<ButtonProps> = ({
  children,
  loading,
  onClick,
  disabled = false,
  ...rest
}) => {
  return (
    <LoadingButton
      type="submit"
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      variant="contained"
      size="small"
     sx={{
        borderRadius: "5px",
        mr: 2,
        '& .MuiCircularProgress-circle': {
          color: 'white',
        },
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};

export default ButtonSave;
