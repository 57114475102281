import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { FC } from "react";

const AuthLayout: FC = () => {
  return (
    <>
      <Box className="base-section">
        <Outlet />
      </Box>
    </>
  );
};

export default AuthLayout;
