import { FC } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import request from "../../Utils/request";
import { ErrorResponse } from "../../common/interface/ErrorResponse";
import { handleError } from "../../Utils/error-utils";
import ButtonSave from "../../components/Buttons/ButtonSave";
import ButtonCancel from "../../components/Buttons/ButtonCancel";
import { User } from "@common/interface/User";

interface CreateUserProps {
  setCurrentView: (newData: string) => void;
  currentView: string;
  editUserData: User | null;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .max(40, "Must be 40 characters or less")
    .required("Email ID is required")
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Only alphabets, numbers, dots, underscores, and hyphens are allowed for this field"
    ),
  phone: Yup.string().required("Phone is required"),
});
const CreateEdit: FC<CreateUserProps> = ({
  setCurrentView,
  currentView,
  editUserData,
}) => {
  const localStorageData: any = localStorage?.getItem("userData");
  const loggedUser = JSON.parse(localStorageData);

  const [loading, setLoading] = useState<boolean>(false);
  const initialValue = {
    name: currentView === "update" ? editUserData?.name : "",
    email: currentView === "update" ? editUserData?.email : "",
    phone: currentView === "update" ? editUserData?.phone : "",
  };
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (currentView === "update") {
          await request.put(`user/${editUserData?.id}`, {
            name: values.name,
            email: values.email,
            phone: values.phone,
          });
          setCurrentView("showTable");
        } else {
          await request.post("user", {
            name: values.name,
            email: values.email,
            phone: values.phone,
            userType: "User",
            userLevel: loggedUser.userLevel,
          });
          setCurrentView("showTable");
        }
      } catch (err) {
        const error = err as ErrorResponse;
        handleError(formik, error);
      }
      setLoading(false);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          title={currentView === "update" ? "Update User" : "Create User"}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label={
                  <>
                    Name
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="name"
                value={formik?.values?.name}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.name && Boolean(formik.errors?.name)}
                helperText={formik.touched?.name && formik.errors?.name}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={
                  <>
                    Email
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="email"
                InputProps={{
                  placeholder: "example@email.com",
                }}
                value={formik?.values?.email}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.email && Boolean(formik.errors?.email)}
                helperText={formik.touched?.email && formik.errors?.email}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={
                  <>
                    Phone
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="phone"
                value={formik?.values?.phone}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.phone && Boolean(formik.errors?.phone)}
                helperText={formik.touched?.phone && formik.errors?.phone}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <ButtonSave loading={loading}>Save</ButtonSave>
              <ButtonCancel
                onClick={() => {
                  setCurrentView("showTable");
                }}
              >
                Cancel
              </ButtonCancel>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default CreateEdit;
