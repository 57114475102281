/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, RouteObject } from "react-router";
import { lazy, Suspense } from "react";
import SuspenseLoader from "../components/SuspenseLoader";
import SidebarLayout from "../Layouts/SidebarLayout";
import Occupancy from "../Pages/Benchmarking/Occupancy/TrendsAnalysis";
import Role from "../Pages/Role/index";
import User from "../Pages/User";
import Feature from "../Pages/Feature";
import ChangePassword from "../Pages/ChangePassword";

const Loader = (Component: any) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Dashboard = Loader(lazy(() => import("../Pages/Dashboard")));
const Group = Loader(lazy(() => import("../Pages/Entities/Groups")));
const HotelManagementGroup = Loader(
  lazy(() => import("../Pages/Entities/HotelManagementGroup"))
);
const Hotel = Loader(lazy(() => import("../Pages/Entities/Hotels")));
const PerformanceComparison = Loader(
  lazy(() => import("../Pages/Benchmarking/Occupancy/PerfomanceComparison"))
);
const CompetitiveAnalysis = Loader(
  lazy(() => import("../Pages/Benchmarking/Occupancy/CompatativeAnalysis"))
);
const RevenueOcc = Loader(
  lazy(() => import("../Pages/Benchmarking/RevenueReports/TrendsAnalysis"))
);
const RevenueCompatativeAnalysis = Loader(
  lazy(() => import("../Pages/Benchmarking/RevenueReports/CompatativeAnalysis"))
);
const ADROcc = Loader(
  lazy(() => import("../Pages/Benchmarking/ADRReport/TrendsAnalysis"))
);
const ADRCompatativeAnalysis = Loader(
  lazy(() => import("../Pages/Benchmarking/ADRReport/CompatativeAnalysis"))
);
const OccvAdr = Loader(lazy(() => import("../Pages/Benchmarking/RevParADR")));
const Report = Loader(lazy(() => import("../Pages/Settings/Report")));
const Event = Loader(lazy(() => import("../Pages/Settings/Event")));
const PMS = Loader(lazy(() => import("../Pages/Settings/Pms")));

const PieChart = Loader(
  lazy(() => import("../Pages/Benchmarking/Occupancy/PieChart"))
);
const OccupancyPrediction = Loader(
  lazy(() => import("../Pages/Benchmarking/Occupancy/OccupancyPrediction"))
);

const AdminSiteRoutes: RouteObject[] = [
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/benchmarking/occupancy/trend-analysis",
        element: <Occupancy />,
      },
      {
        path: "/benchmarking/occupancy/competitive-analysis",
        element: <CompetitiveAnalysis />,
      },
      {
        path: "/benchmarking/performance-comparison",
        element: <PerformanceComparison />,
      },
      {
        path: "/benchmarking/occupancy/occupancy-prediction",
        element: <OccupancyPrediction />,
      },
      {
        path: "/benchmarking/occupancy/index",
        element: <PieChart />,
      },
      {
        path: "/benchmarking/revenue/trend-analysis",
        element: <RevenueOcc />,
      },
      {
        path: "/benchmarking/revenue/competitive-analysis",
        element: <RevenueCompatativeAnalysis />,
      },
      {
        path: "/benchmarking/adr/trend-analysis",
        element: <ADROcc />,
      },
      {
        path: "/benchmarking/adr/competitive-analysis",
        element: <ADRCompatativeAnalysis />,
      },
      {
        path: "/benchmarking/adr-vs-revpar",
        element: <OccvAdr />,
      },
    ],
  },
  {
    path: "",
    element: <SidebarLayout />,
    children: [
      {
        path: "/user",
        element: <User />,
      },
      {
        path: "/user/feature",
        element: <Feature />,
      },
      {
        path: "/user/role",
        element: <Role />,
      },
      {
        path: "/entities/hotel-group",
        element: <Group />,
      },
      {
        path: "/entities/hotel-management-group",
        element: <HotelManagementGroup />,
      },
      {
        path: "/entities/hotels",
        element: <Hotel />,
      },
      {
        path: "/settings/report",
        element: <Report />,
      },
      {
        path: "settings/event",
        element: <Event />,
      },
      {
        path: "settings/pms",
        element: <PMS />,
      },
    ],
  },
  {
    path: "/change-password",
    element: <ChangePassword/>,
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" replace />,
  },
];
export default AdminSiteRoutes;
