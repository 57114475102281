import ButtonSave from "../../components/Buttons/ButtonSave";
import { Box, InputAdornment, IconButton, OutlinedInput, FormControl, InputLabel, Typography } from '@mui/material'
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/material/styles";
import ButtonCancel from "../../components/Buttons/ButtonCancel";
import React, { useState } from 'react';
import request from "../../Utils/request";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router';
import { Visibility, VisibilityOff } from "@mui/icons-material";



function ChangePass() {


    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmNewPassword = () => setShowConfirmNewPassword((show) => !show);




    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };





    const schema = yup.object().shape({
        oldPassword: yup
            .string()
            .required('Enter new password')
            .min(8, 'Too Short!')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})/,
                'Password Should Be At Least 8 Characters Must Include Uppercase, Lowercase, Number and Special Characters'
            ),
        newPassword: yup
            .string()
            .required('Enter new password')
            .min(8, 'Too Short!')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})/,
                'Password Should Be At Least 8 Characters Must Include Uppercase, Lowercase, Number and Special Characters'
            )
            .matches(
                /^[^\s]*$/, 
                'Password should not contain spaces'
              ),
        confirmNewPassword: yup
            .string()
            .required('Confirm password is required')
            .oneOf([yup.ref('newPassword')], 'Passwords must match')
    });
    const ErrorMessage = styled(Box)(
        () => `
              color: red;
              font-weight: bold;
          `
    );

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
        },

        validationSchema: schema,
        onSubmit: async (data) => {
            try {
                setLoading(true);
                const localStorageData: any = localStorage?.getItem("userData");
                const userData = JSON.parse(localStorageData);
                if (data.newPassword === data.confirmNewPassword) {
                    if (userData.accessToken) {
                        const loginData = await request.put("auth/changePassword ", {
                            oldPassword: data.oldPassword,
                            newPassword: data.newPassword,
                            token: userData?.accessToken
                        });

                        toast.success(loginData?.data?.response);
                        localStorage.clear();
                    }
                }
                setLoading(false);
                navigate('/login');
            } catch (err: any) {
                toast.error(err.data.message);
                setLoading(false);
            }

        },

        onReset: () => {
            formik.values.oldPassword = ""
            formik.values.newPassword = ""
            formik.values.confirmNewPassword = ""
        }
    });
    return (
        <>
            <Typography variant="h5" style={{textAlign:'center', color:'grey',marginTop:'20px'}}>Set a new password to update your account security  </Typography>
            <br />
            <form onSubmit={formik.handleSubmit}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">
                        Current password
                    </InputLabel>
                    <OutlinedInput
                        name="oldPassword"
                        label="Current password"
                        value={formik.values.oldPassword}
                        fullWidth
                        type={showOldPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowOldPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={formik.handleChange}
                        error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                    />
                </FormControl>
                <ErrorMessage>
                    {formik.touched.oldPassword && formik.errors.oldPassword}
                </ErrorMessage>
                <FormControl variant="outlined" fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="outlined-adornment-password">
                        New password
                    </InputLabel>
                    <OutlinedInput
                        name="newPassword"
                        label="New password"
                        value={formik.values.newPassword}
                        fullWidth
                        type={showNewPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    />
                </FormControl>
                <ErrorMessage>
                    {formik.touched.newPassword && formik.errors.newPassword}
                </ErrorMessage>
                <FormControl variant="outlined" fullWidth style={{ marginTop: 10 }}>
                    <InputLabel htmlFor="outlined-adornment-password">
                        Confrim password
                    </InputLabel>
                    <OutlinedInput
                        name="confirmNewPassword"
                        label="Confrim password"
                        value={formik.values.confirmNewPassword}
                        fullWidth
                        type={showConfirmNewPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={formik.handleChange}
                        error={formik.touched.confirmNewPassword && Boolean(formik.errors.confirmNewPassword)}
                    />
                </FormControl>
                <ErrorMessage>
                    {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                </ErrorMessage>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                    <ButtonSave loading={loading}>Submit</ButtonSave>
                    <ButtonCancel onClick={() => { formik.resetForm() }} disabled={loading}>Cancel</ButtonCancel>
                </div>
            </form>
        </>
    )
}

export default ChangePass;