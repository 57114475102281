/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, RouteObject } from "react-router";
import { lazy, Suspense } from "react";
import SuspenseLoader from "../components/SuspenseLoader";
import BaseLayout from "../Layouts/BaseLayout";
import AuthLayout from "../Layouts/AuthLayout";
const Loader = (Component: any) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const Login = Loader(lazy(() => import("../Pages/Login")));
const Home = Loader(lazy(() => import("../Pages/Home")));
const ResetPassword = Loader(lazy(() => import("../Pages/ResetPassword")));
const ForgotPassword = Loader(lazy(() => import("../Pages/ForgotPassword")));

const baseSiteRoute: RouteObject[] = [
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
  {
    path: "",
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
     
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
];
export default baseSiteRoute;
