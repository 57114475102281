import {
  Box,
  Container,
  Grid,
  // List,
  // ListItem,
  // ListItemText,
  Stack,
} from "@mui/material";
import logoFooter from "./img/hacsLogoBlack.svg";
import BackToTop from "./BackToTop";
import PhoneIcon from "@mui/icons-material/Phone";
import PlaceIcon from "@mui/icons-material/Place";

const today = new Date();
const year = today.getFullYear();

function Footer() {
  return (
    <Box className="footer">
      <BackToTop />
      <Box className="footerSvgWrapper" width="100%" />
      <Container className="footerContainer">
        <Grid
          container
          spacing={2}
          sx={{ alignItems: "center", justifyContent: "space-around" }}
        >
          <Grid item xs={5} md={3}>
            <img src={logoFooter} className="img-fluid" alt="" />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ alignContent: "center" }}
            className="contactSection"
          >
            <span>
              <PhoneIcon />
              &nbsp; (901) 337 7834
            </span>
            <br />
            <span>
              <PlaceIcon />
              &nbsp; 7819 Old Falls Road, Mclean, Virginia 22102
            </span>
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Grid
              container
              spacing={0.5}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={6} md={4}>
                <List className="footerLinks">
                  <ListItem>
                    <ListItemText>
                      why <span className="hacs">hACS</span>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>pricing</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>Solutions</ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className="footerLinks">
                  <ListItem>
                    <ListItemText>Resources</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>about us</ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>contact us</ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{ justifyContent: "center", display: "flex" }}
          >
            <Stack direction="row" spacing={1}>
              <Box className="socialIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={48}
                  height={48}
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <circle cx={24} cy={24} r={24} fill="#073857" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.6019 15.1574C17.6883 15.1574 16.8122 15.5203 16.1663 16.1663C15.5203 16.8122 15.1574 17.6883 15.1574 18.6019V28.6481C15.1574 29.1005 15.2465 29.5484 15.4196 29.9663C15.5927 30.3842 15.8464 30.7639 16.1663 31.0837C16.4861 31.4036 16.8658 31.6573 17.2837 31.8304C17.7016 32.0035 18.1495 32.0926 18.6019 32.0926H28.6481C29.1005 32.0926 29.5484 32.0035 29.9663 31.8304C30.3842 31.6573 30.7639 31.4036 31.0837 31.0837C31.4036 30.7639 31.6573 30.3842 31.8304 29.9663C32.0035 29.5484 32.0926 29.1005 32.0926 28.6481V18.6019C32.0926 18.1495 32.0035 17.7016 31.8304 17.2837C31.6573 16.8658 31.4036 16.4861 31.0837 16.1663C30.7639 15.8464 30.3842 15.5927 29.9663 15.4196C29.5484 15.2465 29.1005 15.1574 28.6481 15.1574H18.6019ZM18.6019 12H28.6481C29.5151 12 30.3736 12.1708 31.1746 12.5025C31.9755 12.8343 32.7033 13.3206 33.3164 13.9336C33.9294 14.5467 34.4157 15.2745 34.7475 16.0754C35.0792 16.8764 35.25 17.7349 35.25 18.6019V28.6481C35.25 29.5151 35.0792 30.3736 34.7475 31.1746C34.4157 31.9755 33.9294 32.7033 33.3164 33.3164C32.7033 33.9294 31.9755 34.4157 31.1746 34.7475C30.3736 35.0792 29.5151 35.25 28.6481 35.25H18.6019C17.7349 35.25 16.8764 35.0792 16.0754 34.7475C15.2745 34.4157 14.5467 33.9294 13.9336 33.3164C13.3206 32.7033 12.8343 31.9755 12.5025 31.1746C12.1708 30.3736 12 29.5151 12 28.6481V18.6019C12 16.8509 12.6956 15.1717 13.9336 13.9336C15.1717 12.6956 16.8509 12 18.6019 12Z"
                    fill="#F2F2F2"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.0254 21.1643C23.5178 21.089 22.9993 21.1757 22.5438 21.4121C22.0883 21.6484 21.7189 22.0224 21.4882 22.4808C21.2575 22.9392 21.1772 23.4586 21.2587 23.9653C21.3402 24.472 21.5795 24.94 21.9423 25.3029C22.3052 25.6658 22.7733 25.905 23.2799 25.9865C23.7866 26.0681 24.3061 25.9877 24.7645 25.757C25.2228 25.5263 25.5968 25.1569 25.8332 24.7014C26.0695 24.2459 26.1562 23.7274 26.081 23.2198C26.0042 22.702 25.7629 22.2226 25.3927 21.8525C25.0226 21.4823 24.5432 21.2411 24.0254 21.1643ZM21.0896 18.6095C22.1346 18.0672 23.324 17.8683 24.4886 18.041C25.6765 18.2172 26.7762 18.7707 27.6254 19.6199C28.4745 20.469 29.0281 21.5688 29.2042 22.7567C29.3769 23.9212 29.178 25.1106 28.6358 26.1556C28.0935 27.2006 27.2356 28.048 26.184 28.5773C25.1324 29.1066 23.9407 29.2909 22.7783 29.1038C21.616 28.9168 20.5422 28.368 19.7097 27.5355C18.8772 26.7031 18.3284 25.6293 18.1414 24.4669C17.9544 23.3046 18.1386 22.1128 18.6679 21.0612C19.1972 20.0096 20.0446 19.1517 21.0896 18.6095Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M31.3275 17.9333C31.3275 18.673 30.7277 19.2728 29.9879 19.2728C29.2482 19.2728 28.6484 18.673 28.6484 17.9333C28.6484 17.1935 29.2482 16.5938 29.9879 16.5938C30.7277 16.5938 31.3275 17.1935 31.3275 17.9333Z"
                    fill="#F2F2F2"
                  />
                </svg>
              </Box>
              <Box className="socialIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={29}
                  height={29}
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <path
                    d="M27.7009 8.37595C26.7918 8.7797 25.8152 9.0514 24.7901 9.17395C25.837 8.54695 26.6398 7.5542 27.0179 6.3705C26.0394 6.95095 24.9545 7.37275 23.7993 7.60075C22.8749 6.6156 21.5582 6 20.1009 6C17.3022 6 15.0337 8.26955 15.0337 11.0673C15.0337 11.4644 15.0793 11.852 15.1648 12.2215C10.9534 12.0106 7.2199 9.99285 4.7195 6.92625C4.2844 7.67485 4.03455 8.54505 4.03455 9.4751C4.03455 11.2326 4.9285 12.7839 6.28795 13.6922C5.45765 13.6656 4.6758 13.4376 3.99275 13.0585C3.99275 13.0804 3.99275 13.1003 3.99275 13.1221C3.99275 15.5779 5.73885 17.6261 8.0578 18.0906C7.63315 18.2065 7.18475 18.2683 6.7221 18.2683C6.39625 18.2683 6.078 18.236 5.76925 18.178C6.4143 20.1911 8.2858 21.6569 10.5031 21.6978C8.76935 23.0572 6.58435 23.8676 4.20935 23.8676C3.80085 23.8676 3.3971 23.8439 3 23.7964C5.24295 25.2337 7.9058 26.0726 10.7681 26.0726C20.0896 26.0726 25.1853 18.3509 25.1853 11.6544C25.1853 11.4349 25.1806 11.2165 25.1711 10.9989C26.1619 10.2835 27.0217 9.3915 27.7009 8.37595Z"
                    fill="#073857"
                  />
                </svg>
              </Box>
              <Box className="socialIcons">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={48}
                  height={48}
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <circle cx={24} cy={24} r={24} fill="#F2F2F2" />
                  <path
                    d="M29.2139 24.6562L29.8363 20.5844H25.9452V17.9419C25.9452 16.8281 26.4886 15.7419 28.2312 15.7419H30V12.275C28.9617 12.1066 27.9124 12.0147 26.8607 12C23.658 12 21.5618 13.95 21.5618 17.4806V20.5837H18V24.6562H21.5618V34.5H25.9452V24.6562H29.2139Z"
                    fill="#073857"
                  />
                </svg>
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Box className="copyright" sx={{ textAlign: "center" }}>
          © {year} <span>HACS</span>. All Rights Reserved.
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
