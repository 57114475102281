import { FC, useEffect } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  Checkbox,
  TableHead,
  TableContainer,
  Paper,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Role } from "../../common/interface/Role";
import request from "../../Utils/request";
import { ErrorResponse } from "../../common/interface/ErrorResponse";
import { handleError } from "../../Utils/error-utils";
import ButtonSave from "../../components/Buttons/ButtonSave";
import ButtonCancel from "../../components/Buttons/ButtonCancel";
import { FeatureData } from "../../common/interface/FeatureData";
import TableDataLoader from "../../components/DataLoader/TableDataLoader";
import ErrorMessage from "../../components/ErrorMessage";

interface CreateRoleProps {
  setShowTable: (show: boolean) => void;
  setEditData: (data: Role | null) => void;
  editData: Role | null;
  editStatus: boolean;
  setEditStatus: (status: boolean) => void;
}

const baseRoles = [
  { key: "Admin", value: "Admin" },
  { key: "User", value: "User" },
];
const roleValidationSchema = Yup.object({
  name: Yup.string()
    .max(20, "Must be 15 characters or less")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
    .required("Name cannot be empty"),
  baseRole: Yup.string().required("A base role is required"),
  feature: Yup.array()
    .min(1, "You should select atleast one checkbox")
    .required("Required"),
});
const CreateEditRole: FC<CreateRoleProps> = ({
  setShowTable,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setEditData,
  editData,
  editStatus,
  setEditStatus,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [getfeature, setFeature] = useState<FeatureData[]>([]);

  const getFeatures = async (type: any) => {
    try {
      const response = await request.get(`feature/type/${type}`);
      setFeature(response.data.data);
    } catch (err) {
      const error = err as ErrorResponse;
      handleError(formik, error);
    }
  };

  const roleInitialValue = {
    name: "",
    baseRole: "",
    feature: [] as string[],
  };
  const formik = useFormik({
    initialValues: roleInitialValue,
    validationSchema: roleValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const roleData = {
          name: values?.name,
          baseRole: values?.baseRole,
          feature: values?.feature,
        };

        if (editStatus) {
          await request.put(`roles/${editData?.id}`, roleData);
          formik.resetForm();
          setEditStatus(false);
        } else {
          await request.post("roles", roleData);
          formik.resetForm();
        }
        setShowTable(true);
        setEditData(null);
      } catch (err) {
        const error = err as ErrorResponse;
        handleError(formik, error);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (formik.values.baseRole) {
      getFeatures(formik.values.baseRole);
    }
  }, [formik.values.baseRole]);

  const handleFeature = (featureArray: [], moduleID: any) => {
    if (moduleID === true) {
      formik.setValues((previousData) => ({
        ...previousData,
        feature: [
          ...featureArray.map((data: any) => data.id),
          ...previousData.feature,
        ],
      }));
    } else {
      formik.setValues((previousData) => ({
        ...previousData,

        feature: previousData.feature.filter(
          (val) => !featureArray.map((data: any) => data.id).includes(val)
        ),
      }));
    }
  };
  useEffect(() => {
    if (editStatus) {
      formik.setValues({
        name: editData?.name || '', 
        baseRole: editData?.baseRole || '', 
        feature: (editData?.feature || []).map((data) => 
          data.RoleFeature.featureID.toString()
        ),
      });
    }
  }, [editStatus]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader title={"Create Role"} />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label={
                  <>
                    Role Name
                    <span style={{ color: "red" }}>*</span>
                  </>
                }
                name="name"
                value={formik?.values?.name}
                onChange={formik.handleChange}
                variant="standard"
                fullWidth
                error={formik.touched?.name && Boolean(formik.errors?.name)}
                helperText={formik.touched?.name && formik.errors?.name}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  error={
                    formik.touched.baseRole && Boolean(formik.errors.baseRole)
                  }
                >
                  Base Role
                  <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  value={formik.values.baseRole}
                  name="baseRole"
                  onChange={formik.handleChange}
                  label="Base Role"
                  error={
                    formik?.touched?.baseRole &&
                    Boolean(formik?.errors?.baseRole)
                  }
                >
                  {baseRoles.map((item, index) => (
                    <MenuItem key={index} value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* {formik?.touched?.baseRole && formik?.errors?.baseRole && (
                <ErrorMessage>{formik?.errors?.baseRole}</ErrorMessage>
              )} */}
            </Grid>
            <Grid item xs={12}>
              {formik.values.baseRole &&
                getfeature?.map((feature: any, index: any) => {
                  return (
                    <TableContainer component={Paper} key={index}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>{feature.module}</TableCell>
                            <TableCell>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) => {
                                      const moduleID = e.target.checked;
                                      handleFeature(
                                        feature.featureName,
                                        moduleID
                                      );
                                    }}
                                    checked={feature.featureName.some(
                                      (item: any) =>
                                        formik.values.feature?.includes(item.id)
                                    )}
                                  />
                                }
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {loading ? (
                          <TableDataLoader />
                        ) : (
                          <TableBody>
                            {feature.featureName.map(
                              (featureData: any, index: any) => (
                                <TableRow key={index}>
                                  <TableCell width="80%">
                                    {featureData.name}
                                  </TableCell>
                                  <TableCell>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={"feature"}
                                          value={featureData.id}
                                          onChange={formik.handleChange}
                                          checked={formik.values.feature?.some(
                                            (data) => data === featureData.id
                                          )}
                                        />
                                      }
                                      label=""
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  );
                })}
              &nbsp;
              <ErrorMessage>
                {formik.touched.feature && formik.errors.feature ? (
                  <>{formik.errors.feature}</>
                ) : (
                  ""
                )}
              </ErrorMessage>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <ButtonSave loading={loading}>Save</ButtonSave>
              <ButtonCancel
                onClick={() => {
                  setEditData(null);
                  setShowTable(true);
                }}
              >
                Cancel
              </ButtonCancel>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
};

export default CreateEditRole;
