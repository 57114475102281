import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import { useState } from "react";
import { FC } from "react";
import UserTable from "./UserTable";
import UserCreate from "./CreateEdit";
import Settings from "./Settings";
import { User } from "../../common/interface/User";

const UserApplications: FC = () => {
  const [editUserData, setUserData] = useState<User | null>(null);
  const [currentView, setCurrentView] = useState<string>("showTable");

  return (
    <Container sx={{ mt: 3 }}>
      <Helmet>
        <title>User Management: User</title>
      </Helmet>

      {currentView === "showTable" ? (
        <UserTable
          setUserData={setUserData}
          setCurrentView={setCurrentView}
          editUserData={editUserData}
        />
      ) : currentView === "assign" ? (
        <Settings
          setCurrentView={setCurrentView}
          currentView={currentView}
          editUserData={editUserData}
        />
      ) : (
        <UserCreate
          setCurrentView={setCurrentView}
          currentView={currentView}
          editUserData={editUserData}
        />
      )}
    </Container>
  );
};

export default UserApplications;
