import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
// import { client } from "./Utils/apolloClient";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <HelmetProvider>
    <BrowserRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
serviceWorker.unregister();
