import { Box, styled } from '@mui/material';

interface ErrorMessageProps {
  children?: React.ReactNode;
}

const ErrorMessageBox = styled(Box)(({ theme }) => ({
  color: theme.colors.error.main,
  paddingLeft: 8,
  fontSize: 13
}));

const ErrorMessage: React.FC<ErrorMessageProps> = ({ children, ...rest }) => {
  return <ErrorMessageBox {...rest}>{children}</ErrorMessageBox>;
};
export default ErrorMessage;
