import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import { useState } from "react";
import { FC } from "react";
import FeatureTable from "./FeatureTable";
import CreateEdit from "./CreateEdit";
import { Feature } from "../../common/interface/Feature";
//import UserAssign from "./UserAssign";
//import UserEdit from "./UserEdit";

const UserApplications: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [featureData, setFeatureData] = useState<Feature>();
  const [editStatus, setEditStatus] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [editData, setEditData] = useState<Feature | null>(null);
  const [showTable, setShowTable] = useState<boolean>(true);

  return (
    <Container sx={{ mt: 3 }}>
      <Helmet>
        <title>User Management: Features</title>
      </Helmet>
      {showTable ? (
        <FeatureTable
          setShowTable={setShowTable}
          setEditData={setEditData}
          setEditStatus={setEditStatus}
        />
      ) : (
        <CreateEdit
          editStatus={editStatus}
          setShowTable={setShowTable}
          editData={editData}
          setEditData={setEditData}
          setEditStatus={setEditStatus}
        />
      )}
      {/* {currentView === "assign" ? <UserAssign /> : null}
      {currentView === "edit" ? <UserEdit /> : null} */}
    </Container>
  );
};

export default UserApplications;
