import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { Feature } from "../../common/interface/Feature";
import { PaginationData } from "../../common/interface/PaginationData";
import request from "../../Utils/request";
import { handleError } from "../../Utils/error-utils";
import NoDataAvailable from "../../components/NoDataAvailable";
import TableDataLoader from "../../components/DataLoader/TableDataLoader";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import DeleteDialog from "../../components/DeleteDialog";
import { toast } from "react-toastify";

interface FeatureTableProps {
  setEditData: (featureData: Feature) => void;
  setShowTable: (show: boolean) => void;
  setEditStatus: (show: boolean) => void;
}
const FeatureTable: FC<FeatureTableProps> = ({
  setEditData,
  setShowTable,
  setEditStatus,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [feature, setFeature] = useState<Feature[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationData>({
    search: "",
    offset: 0,
    limit: 25,
    orderDirection: "ASC",
    orderColumn: "createdAt",
    totalCount: 0,
  });
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [deleteFeature, setDeleteFeature] = useState<Feature>();

  useEffect(() => {
    fetchFeature();
  }, [
    pagination.offset,
    pagination.limit,
    pagination.search,
    pagination.orderColumn,
    pagination.orderDirection,
  ]);
  const theme = useTheme();

  const openDltConfmModel = (role: any) => {
    setOpenDialogBox(true);
    setDeleteFeature(role);
  };

  // const changeSearch = (searchValue) => {
  //   setPagination((current) => {
  //     return { ...current, offset: 0, search: searchValue };
  //   });
  // };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await request.delete("feature/" + deleteFeature?.id);
      if (response.data.status) {
        // setDeleteRole(null);
        toast.success(response.data.message);
        setOpenDialogBox(false);
        fetchFeature();
      }
    } catch (err: any) {
      // toast.error(err.message);
      setOpenDialogBox(false);
    }
    setLoading(false);
  };

  const fetchFeature = async () => {
    try {
      setLoading(true);
      const response = await request.get("feature/all/features", {
        params: {
          ...pagination,
        },
      });
      setFeature(response.data.data);
      setPagination((current) => {
        return { ...current, totalCount: response.data.count };
      });
      setLoading(false);
    } catch (err: any) {
      handleError({}, err);
    }
  };
  return (
    <TableContainer component={Paper}>
      <DeleteDialog
        openDeleteDialog={openDialogBox}
        setOpenDeleteDialog={setOpenDialogBox}
        handleDelete={handleDelete}
        //deleteDataName={deleteFeature?.name}
        deleteDataName={deleteFeature?.name ?? "Role Name"}
        loading={loading}
      ></DeleteDialog>
      <>
        <Toolbar sx={{ m: 1 }}>
          <Typography sx={{ flex: "1 1 100%" }} variant="h4" component="div">
            Features
          </Typography>
          <Tooltip title="Create Feature">
            <IconButton
              onClick={() => setShowTable(false)}
              color="primary"
              size="small"
              sx={{ mt: -1, ml: 2 }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          {/* <SearchBox changeSearch={changeSearch} /> */}
        </Toolbar>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width={"5%"}>
                <Typography>#</Typography>
              </TableCell>
              <TableCell width={"25%"}>
                <Typography>Feature</Typography>
              </TableCell>
              <TableCell width={"25%"}>
                <Typography>Name</Typography>
              </TableCell>
              <TableCell width={"25%"}>
                <Typography>Feature Action</Typography>
              </TableCell>
              <TableCell width={"25%"}>
                <Typography>Action</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableDataLoader />
          ) : (
            <>
              {feature.length ? (
                <TableBody>
                  {feature?.map((feature, index) => (
                    <TableRow key={feature?.id}>
                      <TableCell>
                        {pagination.offset * pagination.limit + index + 1}
                      </TableCell>
                      <TableCell>{feature?.feature}</TableCell>
                      <TableCell>{feature?.name}</TableCell>
                      <TableCell>{feature?.action}</TableCell>
                      <TableCell>
                        <Tooltip title={"Edit Feature"}>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              setEditData(feature);
                              setEditStatus(true);
                              setShowTable(false);
                            }}
                          >
                            <EditTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            sx={{
                              "&:hover": {
                                background: theme.colors.error.lighter,
                              },
                              color: theme.palette.error.light,
                            }}
                            onClick={() => openDltConfmModel(feature)}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12}>
                      <NoDataAvailable message="No User Found" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
        {/* {pagination?.totalCount > 10 && (
            <Pagination pagination={pagination} setPagination={setPagination} />
          )} */}
      </>
    </TableContainer>
  );
};

export default FeatureTable;
