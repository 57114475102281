import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FC } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

interface DeleteDialogProps {
  openDeleteDialog: boolean;
  setOpenDeleteDialog: (openDeleteDialog: boolean) => void;

  handleDelete: any;
  deleteDataName: string;
  loading?: any;
}
const DeleteDialog: FC<DeleteDialogProps> = ({
  openDeleteDialog,
  setOpenDeleteDialog,
  handleDelete,
  deleteDataName,
  loading,
}) => {
  return (
    <div>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete {deleteDataName} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ textAlign: "center" }}>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleDelete}
          >
            Confirm
          </LoadingButton>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenDeleteDialog(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DeleteDialog;
