/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState, useEffect } from "react";

import {
  Divider,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  useTheme,
  TableBody,
  Tooltip,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  // Pagination,
} from "@mui/material";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { Role } from "../../common/interface/Role";
import { PaginationData } from "../../common/interface/PaginationData";
import request from "../../Utils/request";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import NoDataAvailable from "../../components/NoDataAvailable";
import TableDataLoader from "../../components/DataLoader/TableDataLoader";
import DeleteDialog from "../../components/DeleteDialog";

interface RoleListingTableProps {
  setShowTable: (show: boolean) => void;
  setEditData: (data: Role) => void;
  setEditStatus: (status: boolean) => void;
}

const RoleListingTable: FC<RoleListingTableProps> = ({
  setShowTable,
  setEditData,
  setEditStatus,
}) => {
  const [roleData, setRoleData] = useState<Role[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [deleteRole, setDeleteRole] = useState<Role>();

  const [pagination, setPagination] = useState<PaginationData>({
    search: "",
    offset: 0,
    limit: 25,
    orderDirection: "DESC",
    orderColumn: "name",
    totalCount: 0,
  });

  useEffect(() => {
    getAllRoles();
  }, [
    pagination.offset,
    pagination.limit,
    pagination.search,
    pagination.orderColumn,
    pagination.orderDirection,
  ]);

  const theme = useTheme();

  const openDltConfmModel = (role: any) => {
    setOpenDialogBox(true);
    setDeleteRole(role);
  };

  //   const changeSearch = (searchValue) => {
  //     setPagination((current) => {
  //       return { ...current, offset: 0, search: searchValue };
  //     });
  //   };

  const getAllRoles = async () => {
    try {
      setLoading(true);
      const response = await request.get("roles", {
        params: {
          ...pagination,
        },
      });
      setRoleData(response.data.data);
      setPagination((current) => {
        return { ...current, totalCount: response.data.count };
      });
      setLoading(false);
    } catch (err: any) {
      toast.error(err.message);
    }
  };
  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await request.delete("roles/" + deleteRole?.id);
      if (response.data.status) {
        // setDeleteRole(null);
        toast.success(response.data.message);
        setOpenDialogBox(false);
        getAllRoles();
      }
    } catch (err: any) {
      toast.error(err.message);
      setOpenDialogBox(false);
    }
    setLoading(false);
  };
  return (
    <TableContainer component={Paper}>
      <DeleteDialog
        openDeleteDialog={openDialogBox}
        setOpenDeleteDialog={setOpenDialogBox}
        handleDelete={handleDelete}
        //deleteDataName={deleteRole?.name}
        deleteDataName={deleteRole?.name ?? "Role Name"}
        loading={loading}
      ></DeleteDialog>
      <Toolbar sx={{ m: 1 }}>
        <Typography sx={{ flex: "1 1 100%" }} variant="h4" component="div">
          Roles
        </Typography>
        <Tooltip title="Create Role">
          <IconButton
            onClick={() => setShowTable(false)}
            color="primary"
            size="small"
            sx={{ mt: -1, ml: 2 }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
        {/* <SearchBox /> */}
      </Toolbar>
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={"5%"}>
              <Typography>#</Typography>
            </TableCell>

            <TableCell width={"30%"}>
              <Typography>Role</Typography>
            </TableCell>
            <TableCell>
              <Typography width={"30%"}>Base Role</Typography>
            </TableCell>

            <TableCell width={"20%"}>
              <Typography>Action</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        {loading ? (
          <TableDataLoader />
        ) : (
          <>
            {roleData.length ? (
              <TableBody>
                {roleData.map((role, index) => {
                  return (
                    <TableRow key={role?.id}>
                      <TableCell>
                        {pagination.offset * pagination.limit + (index + 1)}
                      </TableCell>
                      <TableCell>{role?.name}</TableCell>
                      <TableCell>{role?.baseRole}</TableCell>
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton
                            sx={{
                              "&:hover": {
                                background: theme.colors.primary.lighter,
                              },
                              color: theme.palette.primary.main,
                            }}
                            onClick={() => {
                              setEditData(role);
                              setShowTable(false);
                              setEditStatus(true);
                            }}
                          >
                            <EditTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            sx={{
                              "&:hover": {
                                background: theme.colors.error.lighter,
                              },
                              color: theme.palette.error.light,
                            }}
                            onClick={() => openDltConfmModel(role)}
                          >
                            <DeleteTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>
                    <NoDataAvailable message="No Roles Found" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </>
        )}
      </Table>
      {/* {pagination?.totalCount > 10 && (
        <Pagination pagination={pagination} setPagination={setPagination} />
      )} */}
    </TableContainer>
  );
};

export default RoleListingTable;
