import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
// import Techtaliyalogo from './techtaliya-logo.png';
import HACSLogo from '../../Layouts/BaseLayout/Header/img/hacsLogoBlack.svg'

const LogoWrapper = styled(Box)(() => ({
  width: '52px',
  height: '38px'
}));

function Logo() {
  return (
    <Link to="/">
      <LogoWrapper>
        <img src={HACSLogo} alt={HACSLogo} width="120" />
      </LogoWrapper>
    </Link>
  );
}

export default Logo;
