import axios, { AxiosError } from "axios";
import { extractErrorCodeFromError, formatAxiosError } from "./error-utils";
import { ErrorCodes } from "../common/enums/Errors";

// create an axios instance
const refreshTokenService = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // url = base url + request url
  // timeout: 5000 // request timeout
});

// request interceptor do something before request is sent

refreshTokenService.interceptors.request.use(
  (config: any) => {
    const localStorageData: any = localStorage?.getItem("userData");
    const userData = JSON.parse(localStorageData);
    config.headers = {
      "content-type": "application/json",
      Accept: "application/json",
      Authorization: userData ? "Bearer " + userData?.refreshToken : null,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// interceptor response
refreshTokenService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    const errorCode = extractErrorCodeFromError(error);
    if (errorCode === ErrorCodes.UnAuthorized) {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_ADMIN_UI_URL}`;
    } else {
      console.log(error);
      const formattedError = formatAxiosError(error?.response?.data);
      return Promise.reject(formattedError);
    }
  }
);

export default refreshTokenService;
